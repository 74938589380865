/* Base */
/* Vertical rhythm */
h1, h2, h3, h4, h5, h6,
ul, ol, dd,
p, figure,
pre, table, fieldset,
.list-nav,
.list-angle,
.panel {
    margin-top: 0;
    margin-bottom: $vr;

    &:last-child {
        margin-bottom: 0;
    }
}

input,
button,
select,
textarea {
    font-family: $font-family-serif;
}

