/* User : tab */
.user-tab {
    $this: &;
    @include reset;
    @include clearfix;
    font-size: 1.125rem;
    font-family: $font-family-serif;
    color: #bdb2ae;
    line-height: 1;
    padding-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: -5px;
    margin-right: -5px;
    position: relative;
    padding-right: 20px;
    @include clearfix;

    &:before {
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 1px;
        background: $gray-lighter;
        display: block;

        @media (min-width: $screen-md-min) {
            left: -50%;
            right: -50%;
        }
    }

    li {
        position: relative;
        float: left;
        padding: 0 5px;
    }

    &__inside {
        color: $brand-secondary;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #ebe3d7;
        border: solid #ebe3d7;
        border-width: 1px 1px 0 1px;
        height: 3.8rem;
        border-radius: 3px;
        padding: 0 1rem 0 .9rem;
        margin-top: 1rem;
    }

    &__icon {
        padding-right: 8px;
        font-size: 1.6rem;
    }

    .active {
        position: relative;
        z-index: 2;
        #{$this}__inside {
            background-color: #fff;
        }

        #{$this}__icon {
            color: $brand-primary;
        }
    }

    @media (max-width: $screen-xs-max) {
        li {
            width: 33.3333%;
        }

        #{$this}__inside {
            flex-wrap: wrap;
            border-width: 1px;
            margin: .5rem 0;
            padding: 0 2px;
            @include ellipsis;

            > * {
                padding-left: 0;
                padding-right: 0;
                width: 100%;
                text-align: center;
            }
        }

        #{$this}__icon {
            display: none;
        }
    }

    @media (min-width: $screen-sm-min) {
        display: inline-block;

        &:after {
            content: '';
            position: absolute;
            left: 5px;
            right: 25px;
            bottom: 0;
            height: 6px;
            display: block;
            @include gradient-vertical(transparent, rgba(0, 0, 0, .05));
        }

        #{$this}__inside {
            border-radius: 3px 3px 0 0;
        }

        .active {
            position: relative;
            z-index: 2;
            #{$this}__inside {
                margin-top: .4rem;
                height: 4.4rem;
                z-index: 2;
            }
        }
    }

    @media (min-width: $screen-lg-min) {
        display: inline-block;
    }
}

/* User : Order history */
.table-history {
    tbody > tr > td {
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .cart__price {
        text-align: right;
    }
}

/* Order status */
.order-status {
    margin-bottom: 1rem;

    &__label {
        width: 110px;
        display: inline-block;
    }

    &__number {
        margin-bottom: 1.2rem;

        strong, b {
            color: #1c54a5;
            font-size: 14px;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        font-size: 1.875rem;
        font-family: $font-family-serif;
        line-height: .8;
        padding-right: 20px;
        padding-top: .5rem;
        padding-bottom: .5rem;

        i {
            font-size: 3rem;
            padding-right: .5rem;
        }

        small {
            font-size: 1.125rem;
            display: block;
        }

        @media (min-width: $screen-sm-min) {
            justify-content: flex-end;

        }
    }
}