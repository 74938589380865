// pagination styles

.pagination {
    $this: &;
    @include reset;
    text-align: right;
    font-size: 1.125rem;
    font-family: $font-family-serif;
    position: relative;

    &:after {
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        border: 1px solid $gray-lighter;
        z-index: -1;
    }

    a, span {
        display: block;
        width: 3.75rem;
        height: 3.125rem;
        line-height: 3.125rem;
        text-align: center;
    }

    a {
        @include link-variant($brand-secondary-light-text);
    }

    li:not(#{$this}__prev, #{$this}__next) {
        float: left;
        position: relative;
        margin-right: -1px;

        a, span {
            border: 1px solid $gray-lighter;
        }

        &.current > *,
        a:hover {
            background-color: #f1e9de;
            box-shadow: inset 0 1px 3px rgba(3, 3, 3, .25);
            border-color: #d1cac1;
        }
    }

    li#{$this}__prev,
    li#{$this}__next {
        float: none;
        display: inline-block;
        border: none;

        a {
            width: 2.5rem;
            padding-top: 2px;
            @include link-variant(#e1d4c1, $brand-primary)
        }
    }
}