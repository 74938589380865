@import "variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?wjq76k');
  src:  url('#{$icomoon-font-path}/icomoon.eot?wjq76k#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?wjq76k') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?wjq76k') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?wjq76k#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mobile-menu {
  &:before {
    content: $icon-mobile-menu;
  }
}
.icon-document-ok {
  &:before {
    content: $icon-document-ok;
  }
}
.icon-circle-right-solid {
  &:before {
    content: $icon-circle-right-solid;
  }
}
.icon-arrows {
  &:before {
    content: $icon-arrows;
  }
}
.icon-safe {
  &:before {
    content: $icon-safe;
  }
}
.icon-school {
  &:before {
    content: $icon-school;
  }
}
.icon-company {
  &:before {
    content: $icon-company;
  }
}
.icon-tab-cart {
  &:before {
    content: $icon-tab-cart;
  }
}
.icon-tab-order {
  &:before {
    content: $icon-tab-order;
  }
}
.icon-edit-cart {
  &:before {
    content: $icon-edit-cart;
  }
}
.icon-status-continues {
  &:before {
    content: $icon-status-continues;
  }
}
.icon-status-open {
  &:before {
    content: $icon-status-open;
  }
}
.icon-status-during {
  &:before {
    content: $icon-status-during;
  }
}
.icon-status-close {
  &:before {
    content: $icon-status-close;
  }
}
.icon-circle-ok {
  &:before {
    content: $icon-circle-ok;
  }
}
.icon-circle-user {
  &:before {
    content: $icon-circle-user;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-circle-delete {
  &:before {
    content: $icon-circle-delete;
  }
}
.icon-up-level {
  &:before {
    content: $icon-up-level;
  }
}
.icon-list-check {
  &:before {
    content: $icon-list-check;
  }
}
.icon-credit-cart {
  &:before {
    content: $icon-credit-cart;
  }
}
.icon-cart-tab {
  &:before {
    content: $icon-cart-tab;
  }
}
.icon-user-2 {
  &:before {
    content: $icon-user-2;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-arrow-right2 {
  &:before {
    content: $icon-arrow-right2;
  }
}
.icon-arrow-down2 {
  &:before {
    content: $icon-arrow-down2;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-inbox {
  &:before {
    content: $icon-inbox;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-circle-right {
  &:before {
    content: $icon-circle-right;
  }
}
.icon-cart-top {
  &:before {
    content: $icon-cart-top;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-envelope-o {
  &:before {
    content: $icon-envelope-o;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-remove {
  &:before {
    content: $icon-remove;
  }
}
.icon-times {
  &:before {
    content: $icon-times;
  }
}
.icon-search-plus {
  &:before {
    content: $icon-search-plus;
  }
}
.icon-cog {
  &:before {
    content: $icon-cog;
  }
}
.icon-gear {
  &:before {
    content: $icon-gear;
  }
}
.icon-trash-o {
  &:before {
    content: $icon-trash-o;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-list {
  &:before {
    content: $icon-list;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-expand {
  &:before {
    content: $icon-expand;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-facebook-f {
  &:before {
    content: $icon-facebook-f;
  }
}
.icon-bars {
  &:before {
    content: $icon-bars;
  }
}
.icon-navicon {
  &:before {
    content: $icon-navicon;
  }
}
.icon-reorder {
  &:before {
    content: $icon-reorder;
  }
}
.icon-comment-o {
  &:before {
    content: $icon-comment-o;
  }
}
.icon-comments-o {
  &:before {
    content: $icon-comments-o;
  }
}
.icon-file-text-o {
  &:before {
    content: $icon-file-text-o;
  }
}
.icon-building-o {
  &:before {
    content: $icon-building-o;
  }
}
.icon-angle-double-left {
  &:before {
    content: $icon-angle-double-left;
  }
}
.icon-angle-double-right {
  &:before {
    content: $icon-angle-double-right;
  }
}
.icon-angle-double-up {
  &:before {
    content: $icon-angle-double-up;
  }
}
.icon-angle-double-down {
  &:before {
    content: $icon-angle-double-down;
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up;
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}

