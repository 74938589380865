// footer styles
#footer {
    background-color: #f5f5f5;
    padding: 1.8rem 0 2.5rem 0;
    position: relative;
    z-index: 2;

    @media (min-width: $screen-lg-min) {
        .container {
            padding-left: 35px;
        }
    }

    &:before {
        content: '';
        top: -8px;
        height: 16px;
        left: 0;
        right: 0;
        position: absolute;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='37' height='16.5' viewBox='0 0 1.90819 0.840045'><g><path style='fill:rgb(245,245,245)'  d='M-0 0l0.000287944 0c0.0125416,0.216982 0.434764,0.391268 0.953815,0.391268 0.519035,0 0.941257,-0.174286 0.953799,-0.391268l0.000287944 0 0 0.840045 -1.90819 0 0 -0.840045z'/></g> </svg>");
        background-position: center top;
        background-repeat: repeat-x;

    }

    .list-nav li {
        margin-bottom: 10px;

        a:before {
            display: none !important;
        }

        a:hover {
            color: in;
        }
    }
}

.cookiebar {
    background-color: #fff;
    text-align: center;
    color: $brand-secondary-light-text;
    line-height: 1.2;
    padding: 1.2rem 1rem;

    &__btn {
        padding-top: 1rem;

        .btn {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    a {
        @include link-variant($brand-secondary-light-text, $brand-secondary, underline);
        text-decoration: underline;
    }
}

.footer-container {
    margin-bottom: 1.2rem;
    padding-bottom: 20px;
    background-color: #b2a490;
    position: relative;
}

html[data-useragent*='MSIE 10.0']  {
    .footer-newsletter .inside {
        display: block;
    }
}


.footer-newsletter {
    padding: 1.4rem 1.4rem .2rem 1.4rem;

    .inside {
        display: flex;
        flex-wrap: wrap;
    }

    &__title {
        font-size: 1.875rem;
        padding-right: 2.1rem;
        font-family: $font-family-headline;
        line-height: 1;
        margin-bottom: 1rem;
    }

    &__input {
        flex: 1;
        position: relative;

        input {
            padding-left: 50px;
            @include placeholder(#b2a490);
        }

        &:before {
            content: '\e904';
            font-family: icomoon;
            position: absolute;
            top: 0;
            width: 50px;
            text-align: center;
            font-size: 18px;
            color: #b2a490;
        }
    }
    .btn {
        margin-left: 10px;
    }

    .btn,
    .form-control {
        height: 34px;
        line-height: 1;
    }

    .btn {
        padding-left: 22px;
        padding-right: 22px;
    }

    .checkbox {
        margin-top: 10px;
        margin-bottom: 5px;

        input:before {
            border-radius: 0;
        }
    }

    @media (min-width: $screen-sm-min) {
        padding-left: 4.9rem;
        padding-right: 3.7rem;
        .inside {
            flex-wrap: nowrap;
        }

        &__title {
            margin-bottom: 0;
        }
    }
}

.footer-nav {
    background-color: #e0d3c0;
    margin-left: -20px;
    margin-right: 60px;
    position: relative;
    padding: 2.2rem 0 1.6rem 20px;

    .col-xs-6 {
        min-width: 150px;
    }

    @media (min-width: $screen-lg-min) {
        padding-right: 7.5rem;
    }

}

.footer-social-nav {
    position: absolute;
    left: 100%;
    width: 60px;
    top: 0;
    text-align: center;

    ul, li {
        @include reset;
    }
    a {
        @include square(60px);
        @include link-variant($brand-secondary);
        @include transition(background-color .3s);
        line-height: 60px;
        font-size: 22px;
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, .1);

        &:hover {
            background-color: rgba(255, 255, 255, .1);
        }
    }
}

.footer-copyrights {
    position: relative;
    padding-left: 50px;
    margin-left: 1.2rem;
    font-size: 11px;
    line-height: 1.45;

    a {
        @include link-variant($brand-secondary);
    }

    &__home {
        float: left;
        height: 20px;
        width: 30px;
        border-right: 1px solid $gray-lighter;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -9px;
        font-size: 14px;

        a {
            display: block;
        }
    }

    @media (min-width: $screen-sm-min) {
        float: left;
    }
}

.footer-payment {
    padding-top: 1rem;
    margin-right: 1.2rem;
    margin-left: 1.2rem;
    @media (min-width: $screen-sm-min) {
        float: right;
        padding-top: 0;
    }
}

.link-top {
    @include bg-sprite(60px, 90px, 0, 0);
    position: absolute;
    left: 50%;
    margin-left: -40px;
    top: 100%;
    margin-top: -20px;
    cursor: pointer;
    @include text-hide;

    @media (max-width: $screen-xs-max) {
        right: 0;
        left: auto;
        margin: 0;
    }
}




