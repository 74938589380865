#left {
    font-family: $font-family-serif;
    font-size: 1rem;
}

.widget {
    margin-bottom: $vr;

    &__title {
        font-family: $font-family-headline;
        font-size: 1.5rem;
        margin-bottom: 4px;
    }
}

.nav-aside {
    font-size: 1.125rem;

    ul, li {
        @include reset();
    }

    a {
        @include link-variant($brand-secondary)
    }

    li {
        position: relative;
        line-height: 1.25;

        a {
            display: inline-block;
        }
    }

    > ul > li {
        margin-bottom: .2rem;


        > a {
            padding-left: 1.4rem;
            &:before {
                content: '\e909';
                font-family: icomoon;
                color: #e0d3c0;
                font-size: .4em;
                position: absolute;
                left: 0;
                top: .5rem;
            }

            &:hover {
                color: $brand-primary;
            }
        }

        > * {
            &.active,
            &.trail {
                color: $brand-primary;

                &:before {
                    color: $brand-primary;
                }
            }
        }


        ul {
            font-size: 1rem;
            margin-left: 1.4rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
            color: $brand-secondary-light-text;

            li {
                > a {
                    padding-left: 1rem;
                    color: inherit;

                    &:before {
                        content: '\f061';
                        font-family: icomoon;
                        color: #d1d9e5;
                        font-size: .4em;
                        position: absolute;
                        left: 0;
                        top: .5rem;
                    }
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }

                > * {
                    &.active,
                    &.trail {
                        color: $brand-secondary;

                        &:before {
                            color: #5f6670;
                        }
                    }
                }


            }
        }

        > ul {
            border-bottom: 2px solid $brand-primary;
            padding-bottom: 1.1rem;
            margin-bottom: 1.4rem;
        }
    }
}