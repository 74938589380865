/* Logout */
.logout {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > *:not(:last-child) {
        padding-right: 20px;
    }

    &__name {
        color: $brand-primary;
        font-family: $font-family-sans-serif;
        font-weight: bold;
        font-size: 14px;
        position: relative;

        &:before {
            content: '\e910';
            font-family: icomoon;
            margin-right: 8px;
            color: #cfd7e4;
            font-size: .8em;
        }
    }

    &__btn {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    border-bottom: 1px solid $gray-lighter;

    @media (min-width: $screen-md-min) {
        border-bottom: none;
        display: block;
    }
}

/* Order */
.order {
    @include clearfix;
    margin: 0 -10px;

    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;

    > * {
        padding: 0 20px;
    }

    @media (min-width: $screen-md-min) {

        margin: 0;

        &__left {
            float: left;
            width: 22%;
        }

        &__right {
            width: 78%;
            float: left;
            border-left: 1px solid $gray-lighter
        }
    }

}

/* Order : step */
.order-step {
    $this: &;
    @include reset;
    @include clearfix;
    font-size: 1.125rem;
    font-family: $font-family-serif;
    color: #bdb2ae;
    line-height: 1;
    text-align: center;
    margin-bottom: 1rem;
    margin-left: -5px;
    margin-right: -5px;
    position: relative;

    &:before {
        content: '';
        left: 0;
        right: 0;
        bottom: 12px;
        position: absolute;
        height: 1px;
        background: $gray-lighter;
        display: block;

        @media (min-width: $screen-md-min) {
            left: -50%;
            right: -50%;
        }
    }

    li {
        position: relative;
        padding: 0 5px;
        width: 24.5%;
        float: left;
    }

    @media (min-width: $screen-sm-min) {
        margin-left: -10px;
        margin-right: -10px;

        li {
            padding-left: 10px;
            padding-right: 10px;
        }

        li:not(:last-child) {
            &:after {
                content: '\e90b';
                font-family: icomoon;
                position: absolute;
                right: -1rem;
                width: 2rem;
                text-align: left;
                top: 50%;
                height: 2rem;
                line-height: 2rem;
                margin-top: -2.15rem;
                color: #ece5da;
            }
        }
    }

    a {
        color: inherit;
    }

    &__inside,
    &__name,
    &__icon {
        display: block;
        text-align: center;
    }

    &__inside {
        padding: 1.8rem 8px;
        position: relative;
        overflow: hidden;
    }

    &__name {
        margin-bottom: .7rem;
    }

    &__icon {
        color: $brand-secondary;
        font-size: 1.5rem;
    }

    li:not(.active) {
        a:hover {
            color: $brand-primary;

            #{$this}__icon {
                color: inherit;
            }
        }
    }

    .active {
        #{$this}__inside {
            background-color: $brand-primary;
            color: #FFFFFF;

            &:before {
                content: '';
                left: 2px;
                right: 2px;
                top: 2px;
                bottom: 2px;
                position: absolute;
                border: 2px dashed #FFFFFF;
            }
        }

        #{$this}__name {
            text-shadow: 1px 1px rgba(0, 0, 0, .2);
        }

        #{$this}__icon {
            color: inherit;
            font-size: 2rem;
        }
    }

    @media (min-width: $screen-sm-min) {
        #{$this}__inside {
            width: 150px;
            height: 113px;
        }
        .active {
            #{$this}__inside {
                background-color: transparent;
                @include bg-sprite(150px, 113px, -210px, 0);

                &:before {
                    display: none;
                }

            }
        }
    }
}

/* Order : Cart */

@media (max-width: $screen-xs-max) {
    .hidden-td-xs {
        width: 0;
        padding: 0 !important;
        border: none !important;

        * {
            display: none;
        }
    }
}

.cart {
    $this: &;
    font-size: 1rem;

    .table-responsive td {
        white-space: normal !important;
    }

    table {
        border-width: 1px 0 0 ;
    }

    th {
        white-space: nowrap;
    }

    tbody {
        border-bottom: 1px solid $table-border-color;
        td {
            vertical-align: middle !important;
        }
    }

    tbody,
    tfoot {
        font-family: $font-family-serif;
        font-size: 1rem;
    }

    tfoot td {
        vertical-align: middle !important;
    }

    &__delete {
        padding: 0 0 0 6px !important;
        text-align: center;
        width: 32px;

        .checkbox {
            margin-top: -5px;
        }

        @media (max-width: $screen-xs-max) {
            min-width: 32px;
        }
    }

    &__image,
    &__description {
        border-left: none !important;
        border-right: none !important;
    }

    &__image {
        text-align: center;

        img {
            margin: 15px 0;
            padding: 5px;
            border: 1px solid #ededed;
            width: 80px;
        }

        @media (min-width: $screen-sm-min) {
            width: 170px;
            img {
                width: auto;
            }
        }
    }

    a {
        @include link-variant(inherit, $brand-primary)
    }

    &__quantity {
        width: 92px;

        &-number {
            font-size: 1.125rem;
            padding-left: .5rem;
            font-family: $font-family-serif;
        }
    }

    &__price {
        width: 130px;
        font-size: 1.625rem;
        white-space: nowrap;
        text-align: center;
        font-family: $font-family-serif;

        * {
            white-space: nowrap;
        }
        .currency {
            font-size: 1rem;
        }
    }

    &__product-name {
        font-size: 1.125rem;
        line-height: 1.1;
    }

    &__product-maker {
        color: #bdb2ae;
        line-height: 1.1;
    }

    &__summary-text {
        font-size: 11px;
        line-height: 1.2;
    }

    &__summary-quantity,
    &__summary-price {
        background-color: #fbf8f5;
        vertical-align: middle !important;
        text-align: center;
    }

    &__summary-quantity {
        font-size: 13px;
        white-space: nowrap;
        color: #bdb2ae
    }

    &__summary-price {
        text-align: right;
        color: $brand-primary;
        font-size: 1.625rem;
        line-height: 1 !important;
        white-space: nowrap;
        font-weight: bold;

        &-currency {
            display: block;
            font-size: .875rem;
        }
    }

    &__summary-total {
        background-color: $brand-primary;
        color: #FFFFFF;
        font-family: $font-family-serif;
        text-align: center;
        font-size: 3rem;

        &-currency {
            font-size: 1.875rem;
        }
    }

    &__order-number {
        font-size: 1.125rem;
        line-height: 1;
    }

    &__text-light {
        color: #bdb2ae;
        font-size: 0.875rem;
        line-height: 1;
    }

    &__text-small {
        font-size: 11px;
        padding-top: 10px;
    }

    &__status {
        line-height: 1;
        font-size: 1.125rem;
        width: 10rem;
        white-space: nowrap;

        i {
            font-size: 1.8rem;
            vertical-align: middle;
            margin-right: .5rem;
            line-height: 1;
            position: relative;
            top: -.2rem;
        }
    }

    .label-summary {
        float: right;
        font-size: 1.625rem;
        text-align: right;
        line-height: 1;
        padding-top: .2rem;
    }

    .label-total {
        float: right;
        font-size: 1.875rem;
        text-align: right;
        line-height: .7;
    }

    .btn {
        margin: 5px 5px 5px 0;
        line-height: 1.5;
        @include ellipsis;

        @media (max-width: $screen-xs-max) {
            font-size: 1rem;
        }

    }
}


.cart--order {
    .table > tfoot {
        > tr > td {
            padding-top: 4px ;
            padding-bottom: 4px ;
            line-height: 1.4;

            &.cart__summary-total {
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }

        > tr > td:not(.cart__summary-total ) {
            line-height: 1.1 ;
        }
    }
    tfoot {
        font-family: $font-family-sans-serif;
        font-size: 14px;
    }

    .label-summary {
        font-size: 12px;
        text-align: right;
    }

    .cart__image {
        border-left: 1px solid $table-border-color !important;
    }
}

/* Submit container */
.submit-container {
    text-align: right;
    margin-bottom: 2rem;
    padding-right: 20px;

    .btn {
        span {
            text-shadow: 0 1px 0 rgba(0, 0, 0, .2);
        }
    }
}

.order .submit-container {
    padding-right: 0;
}

hr + .submit-container {
    margin-top: -1rem;
}

/* Shipment */
.label-price {
    font-family: $font-family-serif;
    font-size: 1.625rem;

    &__currency {
        font-size: 1rem;
    }
}

.table--shipment {
    text-align: center;

    td, th {
        vertical-align: middle !important;
        padding-top: 1px !important;
        padding-bottom: 1px !important;
        line-height: 1;
    }

    tbody td {
        height: 3rem;
        white-space: nowrap;
    }

    thead th {
        padding-top: 15px !important;
        padding-bottom: 15px !important;

        @media (min-width: $screen-sm-min) {
            &:first-child {
                width: 180px;
            }
        }
    }
}

