/* ===========================================
 * Product Filter
 * ======================================== */
.product-filter {
    font-family: $font-family-serif;
    margin-left: -5px;
    margin-right: -5px;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 1.8rem;
    padding-bottom: .2rem;

    &__col {
        padding: 0 5px;
        margin-bottom: 1rem;
        display: flex;
        white-space: nowrap;
        align-items: center;

        .dropdown {
            margin-right: 20px;
        }
    }

    label {
        padding-right: 10px;
        margin: 0;
        font-size: 1rem;
        color: $brand-secondary-light-text;
        font-weight: normal;
    }

    input {
        width: 40px;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;

        + label {
            margin-left: 10px;
        }
    }

    select {
        min-width: 70px;
        max-width: 135px;
    }
    select, input {
        border: none;
        box-shadow: none;
        height: 29px;
        line-height: 29px;
        padding-top: 0;
        padding-bottom: 0;
        background-color: $brand-secondary-lighter;
    }

    .btn {
        height: 29px;
        line-height: 1;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 1rem;
    }

    @media (max-width: $screen-sm-max) {
        .form-flex {
            flex-wrap: wrap;
            justify-content: space-around;
        }

        .dropdown {
            margin: 0;
        }

        select {
            max-width: 125px;
        }

        &__col:last-child {
            text-align: right;
            width: 100%;
            justify-content: flex-end;
        }
    }

    @media (max-width: $screen-xs-max) {
        .form-flex {
            justify-content: space-between;
        }

        &__col:last-child {
            width: auto;
        }

        select, input {
            font-size: 1.2rem;
        }

        select {
            max-width: 90px;
        }
    }
}

/* ===========================================
 * Product list
 * ======================================== */
.product-list {
    @include make-row(20px);
    display: flex;
    flex-wrap: wrap;

    &:before {
        display: block;
    }

    @media (min-width: $screen-sm-min) {
        > .slick-list {
            //margin-left: 15px;
            //margin-right: 15px;
        }
    }


    > .product {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 22px;

        &:after {
            content: '';
            left: 10px;
            right: 10px;
            bottom: 0;
            height: 1px;
            background-color: $gray-lighter;
            position: absolute;

        }
    }

    &--col-2 {
        > .product {
            @include make-xs-column(8, 20px);
        }
    }

    &--col-3 {
        > .product {
            @include make-xs-column(8, 20px);
            @media (min-width: $screen-md-min) {
                width: 33.3333%;
            }
        }
    }

    &--col-4 {
        > .product {
            @include make-xs-column(8, 20px);
            @include make-md-column(4, 20px);
        }
    }

    &__see-all {
        text-align: center;
        font-size: 1.125rem;
        font-family: $font-family-serif;
        line-height: 1.2;
        padding-top: 1rem;

        a {
            position: relative;
            z-index: 3;
        }

        @media (min-width: $screen-sm-min) {
            text-align: right;
            padding-top: 0;
        }

    }

    &--carousel {
        margin-left: 0;
        margin-right: 0;
    }

    @media screen and (min-width:0\0) {
        &--carousel {
            display: block;
            flex-wrap: wrap;
        }
    }



    &--carousel:not(.slick-slider) {
        flex-wrap: nowrap;
        overflow: hidden;

        .product {
            min-width: 250px;
        }
    }

    @media (max-width: 540px) {
        margin-left: 0;
        margin-right: 0;

        .product {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

/* ===========================================
 * Product list : Product
 * ======================================== */
.product {
    $this: &;
    font-family: $font-family-serif;
    text-align: center;
    margin-bottom: 2rem;

    &.slick-slide {
        margin-bottom: .6rem;
    }

    &__image {
        @include square(185px);
        max-width: 100%;
        padding: 5px;
        background-color: #fff;
        text-align: center;
        line-height: 175px;
        border: 1px solid $gray-lighter;
        margin-bottom: 1.7rem;
        position: relative;

        img {
            display: inline-block;
        }

        &:after {
            @include skew(45deg, 0);
            @include gradient-vertical(rgba(0, 0, 0, .05), transparent);
            content: '';
            position: absolute;
            left: 14px;
            right: -16px;
            height: 30px;
            top: 100%;
            margin-top: 1px;
            z-index: 0;
        }
    }

    &__name {
        position: relative;
        display: block;
        margin: 0;
        font-size: 1.25rem;
        line-height: 1;
        color: $brand-secondary;
    }

    a#{$this}__name {
        @include link-variant($brand-secondary, $brand-primary);
    }

    &__maker {
        font-size: 1.125rem;
        line-height: 1.3;
        color: $brand-secondary-light-text;
    }

    &__action {
        position: absolute;
        right: -37px;
        bottom: 25px;
        line-height: 1;
    }

    &__discount,
    &__cart {
        @include square(44px);
        background-color: $brand-primary;
        color: #FFFFFF;
        display: block;
        line-height: 44px;
        font-size: 22px;
        white-space: nowrap;
        margin-top: 1px;
    }

    &__cart {
        @include transition(background-color .2s);
        text-decoration: none;
        padding-top: 2px;

        &:hover {
            text-decoration: none;
            color: #FFFFFF;
            background-color: darken($brand-primary, 10%);
        }
    }

    &__discount {
        background-color: #71e65a;

        small {
            font-size: .6em;
        }
    }

    &__price {
        padding-top: .7rem;
    }

    @media (max-width: $screen-sm-max) {

        > .inside {
            text-align: left;
            display: flex;
            align-items: center;
        }

        &__discount,
        &__cart {
            display: inline-block;
            vertical-align: middle;
            margin-left: 1px;
        }

        &__action {
            bottom: -1rem;
            left: 0;
            right: 0;
            text-align: center;
            z-index: 2;
            white-space: nowrap;
        }

        &__image {
            float: left;
            width: 50%;
        }

        &__text {
            width: 50%;
            padding-left: 20px;
        }
    }
}

/* Product list : Product : Price */
.price {
    font-size: 1.875rem;
    color: $brand-primary;
    white-space: nowrap;
    display: inline-block;
    line-height: 1;

    .currency {
        font-size: 1rem;
    }

    &--old {
        text-decoration: line-through;
        color: $brand-secondary-light-text;
        font-size: 1.125rem;

        + .price {
            padding-left: 2px;
        }
    }
}

/* Product list : Product in header carousel */
#header {
    @media (min-width: $screen-sm-min) {
        .product__image {
            margin-bottom: .6rem;
            margin-left: auto;
            margin-right: auto;
        }

        .product__name {
            line-height: 1.05;
        }
        .product__price {
            padding-top: .3rem;
        }
    }

    @media (min-width: $screen-lg-min) {
        .product__image {
            left: -27px;
        }
    }
}

/* ===========================================
 * Product Page
 * ======================================== */
.panel {
    @media (min-width: $screen-sm-min) {
        .slick-list {
            margin-right: -25px;
        }
        .slick-slide {
            padding-right: 25px;
        }
    }

    .product__image:after {
        height: 20px;
        left: 9px;
        right: -10px;
    }
}

.product {
    $this: &;

    &--single {
        text-align: left;

        #{$this}__image {
            width: auto;
            height: auto;
            line-height: 1;
            float: none;

            a {
                display: block;
            }
        }

        #{$this}__name {
            font-size: 2.25rem;
        }

        *[class^="col-"] {
            margin-bottom: $vr;
        }
    }

    &__container {
        position: relative;
        font-size: 1rem;

        &__gallery {
            @include clearfix;
        }

        .row {
            @media (min-width: $screen-sm-min) {
                margin-left: -20px;
                margin-right: -20px;

                > * {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }


        @media (min-width: 400px) and (max-width: $screen-sm-max) {
            &__gallery {
                float: left;
                max-width: 40%;

                + * {
                    overflow: hidden;
                }
            }
        }

        @media (min-width: $screen-md-min) {
            padding-left: 37%;
            margin-left: 20px;

            &__gallery {
                width: 38%;
                position: absolute;
                left: 0;
                top: .5rem;
                margin-left: -20px;
            }
        }
    }

    &__subname {
        color: $brand-primary;
        font-size: 1.125rem;
        margin-bottom: 1rem;
    }

    &__btn {
        .btn-lg {
            margin-bottom: 1rem;
        }

    }

    &__col-right {
        padding-top: 1rem;

        &:before {
            content: '';
            left: 15px;
            right: 15px;
            top: 0;
            height: 3px;
            border: dashed #ebe3d7;
            border-width: 1px 0;
            position: absolute;
        }

        @media (min-width: $screen-md-min) {
            text-align: left;
            padding-top: 0;

            &:before {
                left: 0;
                bottom: 0;
                height: auto;
                width: 3px;
                border-width: 0 1px;
            }

        }
    }
}

.product-price {
    $this: &;
    font-size: 1rem;
    color: $brand-secondary;
    line-height: 1;
    margin-bottom: .5rem;

    &__value {
        font-size: 2.25rem;
        color: $brand-primary;
    }

    &__currency {
        font-size: .8em;
    }

    &--old {
        margin-bottom: .5rem;
        color: $brand-secondary-light-text;

        #{$this}__value {
            color: inherit;
            font-size: 1.125rem;
            text-decoration: line-through;
        }
    }
}

/* Product page : Attributes list */
.attributes-list {
    @include reset;
    margin-bottom: 1.5rem;
    line-height: 1.42;

    strong, b {
        color: $brand-secondary;
        font-weight: normal;
    }
}

/* Product page : Shopping info */
.shopping-info {
    position: relative;
    padding-left: 2.5rem;
    line-height: 1.42;
    font-size: 0.875rem;

    &:before {
        content: '\e903';
        font-family: icomoon;
        position: absolute;
        left: .3rem;
        top: 1rem;
        color: #d5dce7;
        font-size: 1.5rem;
        line-height: 0;
    }

    &__time {
        color: $brand-primary;
        font-size: 1.125rem;
        padding-bottom: 2px;
        line-height: 1.1;
    }
}

/* Product page : Shopping info */
.product-gallery {
    line-height: 1;
    margin-bottom: 1.2rem;
    overflow: hidden;

    ul {
        @include reset;
        overflow: hidden;
        padding-top: 1rem;
        margin-bottom: -1.75rem;
        margin-right: -1.75rem;
    }

    li {
        float: left;
        margin-right: 1.75rem;
        margin-bottom: 1.75rem;

        a {
            @include square(98px);
            border: 1px solid #ebe3d7;
            border-radius: 5px;
            display: block;
            line-height: 78px;
            padding: 7px;
            position: relative;

            &.active {
                border-width: 3px;
                padding: 5px;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 100%;
                    margin-bottom: 3px;
                    left: 50%;
                    margin-left: -5px;
                    @include triangle(10px, #ebe3d7, up);
                }
            }
        }
    }

    @media (max-width: $screen-xs-max) {


        ul {
            margin-bottom: -1rem;
            margin-right: -1rem;
        }

        li {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }


    }

    + hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

.section-information,
.section-promotions {
    font-family: $font-family-serif;
    color: $brand-secondary-light-text;
    font-size: 1rem;
    margin-bottom: 2.5rem;
    overflow: hidden;

    a {
        @include link-variant(inherit, $brand-primary, underline)
    }

    .list-nav {

    }
}

.section-promotions {
    margin-bottom: 1.2rem;
}

