// Todo :: często używany kolor: #bdb2ae
/* Variables */
$font-size-base: 18px;
$line-height-base: 20 / 12;
$font-family-base: Muli, sans-serif;
$text-color: #676f7b;
$brand-primary: #e81d3f;
$container-large-desktop: 1000px;
$padding-base-vertical: 5px;

$brand-secondary: #50332a;
$brand-secondary-light: #ebe3d7;
$brand-secondary-lighter: #f1e9de;
$brand-secondary-light-text: #a2908a;

$color-green: #7cc576;
$color-yellow: #f6ad1a;
$color-violet: #8781bd;
$color-burgundy: #df3c5a;


$text-color: $brand-secondary;
$input-color: $brand-secondary;



$font-family-sans-serif: Arial, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif: 'patrick_handregular', Georgia, "Times New Roman", Times, serif !default;
$font-family-headline: 'love_ya_like_a_sisterregular';

$gray-lighter: #eaeaea;

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

$padding-large-vertical:    4px;
$padding-large-horizontal:  19px;

$font-size-large: 22px;
$padding-base-vertical: 1px;

/* Form */
$form-group-margin-bottom: 10px;
//== Form states and alerts
$state-success-text: #7cc576;
$state-success-bg: #7cc576;
$state-success-border: #85c97f;

/* Alert */


$alert-info-bg:               #f1e9de ;
$alert-info-text: $brand-secondary;
$alert-info-border: #f1e9de;

$state-info-text: #63a4ea;

$state-warning-text: #ff8d3a;

//== Grid system
$grid-columns:              16;
$grid-gutter-width:         20px;

/* Tooltip */
$tooltip-max-width: 600px;
$tooltip-opacity: 1;
/* Computed variables */
$vr: $line-height-base * 1rem; // vertical rhythm

$link-hover-decoration: red;

/* Typography */
$font-size-h1: 3rem;
$font-size-h2: 2.25rem;
$font-size-h3: 1.375rem;
$font-size-h4: 0.875rem;
$font-size-h5: 1rem;
$font-size-h6: 0.85rem;

/* Panel */
$panel-bg : #f1e9de;
$panel-body-padding: 1.6rem 2.4rem;

/* Table */
$table-cell-padding: 5px 20px;
$table-border-color: #eaeaea;
/* Patch */
$icomoon-font-path: "../fonts/icomoon/fonts";