$icomoon-font-path: "fonts" !default;

$icon-mobile-menu: "\e91f";
$icon-document-ok: "\e91e";
$icon-circle-right-solid: "\e91d";
$icon-arrows: "\e91c";
$icon-safe: "\e917";
$icon-school: "\e91a";
$icon-company: "\e91b";
$icon-tab-cart: "\e916";
$icon-tab-order: "\e918";
$icon-edit-cart: "\e918";
$icon-status-continues: "\e919";
$icon-status-open: "\e926";
$icon-status-during: "\e926";
$icon-status-close: "\e927";
$icon-circle-ok: "\e915";
$icon-circle-user: "\e928";
$icon-info: "\e914";
$icon-edit: "\e913";
$icon-circle-delete: "\e912";
$icon-up-level: "\e90c";
$icon-list-check: "\e90d";
$icon-credit-cart: "\e90e";
$icon-cart-tab: "\e90f";
$icon-user-2: "\e910";
$icon-logout: "\e911";
$icon-arrow-right2: "\e909";
$icon-arrow-down2: "\e902";
$icon-chevron-left: "\e90a";
$icon-chevron-right: "\e90b";
$icon-cart: "\e908";
$icon-inbox: "\e903";
$icon-email: "\e904";
$icon-circle-right: "\e905";
$icon-cart-top: "\e900";
$icon-user: "\e901";
$icon-plus-circle: "\e907";
$icon-home: "\e906";
$icon-search: "\f002";
$icon-envelope-o: "\f003";
$icon-star: "\f005";
$icon-check: "\f00c";
$icon-close: "\f00d";
$icon-remove: "\f00d";
$icon-times: "\f00d";
$icon-search-plus: "\f00e";
$icon-cog: "\f013";
$icon-gear: "\f013";
$icon-trash-o: "\f014";
$icon-download: "\f019";
$icon-list: "\f03a";
$icon-arrow-left: "\f060";
$icon-arrow-right: "\f061";
$icon-arrow-up: "\f062";
$icon-arrow-down: "\f063";
$icon-expand: "\f065";
$icon-facebook: "\f09a";
$icon-facebook-f: "\f09a";
$icon-bars: "\f0c9";
$icon-navicon: "\f0c9";
$icon-reorder: "\f0c9";
$icon-comment-o: "\f0e5";
$icon-comments-o: "\f0e6";
$icon-file-text-o: "\f0f6";
$icon-building-o: "\f0f7";
$icon-angle-double-left: "\f100";
$icon-angle-double-right: "\f101";
$icon-angle-double-up: "\f102";
$icon-angle-double-down: "\f103";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-angle-up: "\f106";
$icon-angle-down: "\f107";

